import david_rendon from "./david_rendon.png";
import humberto_jaimes from "./humberto_jaimes.png";
import luis_beltran from "./luis_beltran.png";
import miguel_munoz from "./miguel_munoz.png";

const images = {
    david_rendon,
    humberto_jaimes,
    luis_beltran,
    miguel_munoz
}
export default images;